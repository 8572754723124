import Cookies from 'js-cookie'

export function useStorage() {
    const type = typeof(Storage) == 'undefined' ? 'cookie' : 'storage'  

    function get(name, def = null) {
        let result = null

        if (type == 'storage') {
            result = localStorage.getItem(name)
        }
        else {
            result = Cookies.get(name)
        }

        if(result === undefined) {
            result = def
        }

        return result
    }

    function getJson(name) {
        const string = get(name)
        return JSON.parse(string)
    }

    function set(name, value) {
        if(type == 'storage') {
            localStorage.setItem(name, value)
        }
        else {
            Cookies.set(name, value)
        }
    }

    function setJson(name, value) {
        value = JSON.stringify(value)
        set(name, value)
    }

    function remove(name) {
        localStorage.removeItem(name)
    }

    function clear() {
        localStorage.clear()
    }

    return { get, set, getJson, setJson, remove, clear }
}
