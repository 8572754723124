export default {
    deliveries: [
        'courier',
        'himself',
    ],

    payments: [
        'card',
        'cash'
    ],
    
    payment_statuses: {
        0: 'fail',
        1: 'success'
    }
}
