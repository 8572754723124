export default {
    limits: [ 20, 50, 100 ],
    sortBy: 'id',
    sortDir: 'desc',
    actives: [
        { val: '', title: 'app.actives_all' },
        { val: '1', title: 'app.actives_yes' },
        { val: '0', title: 'app.actives_no' },
    ],
}
