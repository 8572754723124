<script setup>
import { ref, inject } from 'vue'
import { RouterLink, useRoute } from 'vue-router'
import { useStorage } from '@/composables/storage'

const config = inject('$config')
const user = inject('$user')
const route = useRoute()
const storage = useStorage()

const menuStateStorage = storage.get('menu', 'expanded')
const menuState = ref(menuStateStorage)
const menuToggleState = ref(menuStateStorage)

menuSet()

function menuClick(){
    if(screen.width <= 576) {
        menuClose()
    }
}

function menuSet() {
    if(menuToggleState.value == 'expanded') {
        menuOpen()
    }
    else {
        menuClose()
    }
}

function menuClose() {
    const body = document.body
    body.classList.add('menu-collapsed')
    body.classList.remove('menu-expanded')

    menuToggleState.value = 'collapsed'
    menuState.value = 'collapsed'
}

function menuOpen() {
    const body = document.body
    body.classList.add('menu-expanded')
    body.classList.remove('menu-collapsed')

    menuToggleState.value = 'expanded'
    menuState.value = 'expanded'
}

function menuToggle() {
    menuToggleState.value = menuToggleState.value == 'expanded' ? 'collapsed' : 'expanded'
    storage.set('menu', menuToggleState.value)

    menuSet()
}

function menuMouse(action) {
    if(screen.width <= 576) {
        return
    }

    if(menuToggleState.value == 'collapsed') {
        if(action == 'over') {
            menuState.value = 'expanded'
        }
        else {
            menuState.value = 'collapsed'
        }
    }
    else {
        menuState.value = 'expanded'
    }
}

function checkMenuPermission(key) {
    if(config.roles[key]) {
        return config.roles[key].filter(item => user.roles.indexOf(item) > -1).length > 0
    }

    return true
}

</script>

<template>
    <div :class="[{expanded: menuState == 'expanded'}, 'main-menu menu-fixed menu-light menu-accordion menu-shadow']"
        @mouseover="menuMouse('over')"
        @mouseout="menuMouse('out')">

        <div :class="[{expanded: menuState == 'expanded'}, 'navbar-header']">
            <ul class="nav navbar-nav flex-row">
                <li class="nav-item me-auto">
                    <RouterLink :to="{ name: 'home' }" class="navbar-brand">
                        <h2 class="brand-text">
                            {{ $lang('app.header_title') }}
                        </h2>
                    </RouterLink>
                </li>

                <li class="nav-item nav-toggle">
                    <a @click="menuToggle()" href="#" class="nav-link modern-nav-toggle pe-0 d-none d-xl-block ">
                        <vue-feather v-if="menuToggleState == 'expanded'"
                            type="disc" 
                            size="20"
                            class="collapse-toggle-icon primary" />

                        <vue-feather v-else
                            type="circle" 
                            size="20"
                            class="collapse-toggle-icon primary" />
                    </a>

                    <a @click="menuClose()" href="#" class="nav-link modern-nav-toggle pe-0 d-block d-xl-none">
                        <vue-feather
                            type="x" 
                            size="20"
                            class="text-primary toggle-icon font-medium-4" />
                    </a>
                </li>
            </ul>
        </div>

        <div class="shadow-bottom"></div>

        <div class="main-menu-content">
            <ul class="navigation navigation-main" id="main-menu-navigation" data-menu="menu-navigation">
                <template v-for="item,key in $config.tree.main">
                    <li v-if="checkMenuPermission(key)" 
                        :class="[ 'nav-item', { active: route.name == key } ]">
                        
                        <RouterLink :to="{ name: key }" class="d-flex align-items-center" @click="menuClick()">
                            <vue-feather :type="item.icon" />
                            
                            <span class="menu-title text-truncate">
                                {{ $lang('menu.' + key) }}
                            </span>
                        </RouterLink>
                    </li>
                </template>
            </ul>
        </div>
    </div>
</template>
