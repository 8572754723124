import config from '@/config/config'
import { useStorage } from '@/composables/storage'

export default {
    install: (app) => {
        const storage = useStorage()
        const user = storage.getJson('user')
        
        if(user) {
            user['deletePermission'] = deletePermission()
        }

        if(user) {
            app.provide('$user', user)
            app.config.globalProperties.$user = user
        }

        function deletePermission() {
            if(!user) return

            return user.roles.some(value => {
                return config.deleteRoles.includes(value)
            })
        }
    }
}
