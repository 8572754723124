<script setup>
import { ref, watchEffect } from 'vue'
import { RouterView } from 'vue-router'
import { useRoute } from 'vue-router'
import Header from '@/components/parts/Header.vue'
import MainMenu from '@/components/parts/MainMenu.vue'
import Footer from '@/components/parts/Footer.vue'

const route = useRoute()
const headerContainer = ref(null)
const headerHidden = ['login']

watchEffect(() => {
    if(route.name && headerContainer.value) {
        if(headerHidden.indexOf(route.name) === -1) {
            headerContainer.value.classList.remove('visually-hidden')
        }
    }
})

</script>

<template>
  <div v-if="$user" ref="headerContainer" class="visually-hidden">
    <Header />
    <MainMenu />
  </div>

	<RouterView v-slot="{ Component }">
		<Transition>
			<component :is="Component" />
		</Transition>
	</RouterView>

	<Footer v-if="$user && route.name !== 'login'" />
</template>

<style>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
