import Echo from 'laravel-echo';
import config from '@/config/echo'
import configApi from '@/config/api'
import { useStorage } from '@/composables/storage'
import Pusher from 'pusher-js'

export function useEcho() {
    const storage = useStorage()
    const token = storage.get('token')

    let echoClient = null

    if(token) {
        const params = Object.assign({
            authEndpoint: configApi.baseURL + 'broadcasting/auth',
            auth: {
                headers: {
                    Authorization: 'Bearer ' + token,
                    Accept: 'application/json',
                },
            },
        },
        config.params)
    
        echoClient = new Echo(params)
    }
    
    return { echoClient }
}
