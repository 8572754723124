import Toastify from 'toastify-js'
import { inject } from 'vue'

export function useNotify() {
    const classes = {
        error: 'notify-error',
        success: 'notify-success',
        info: 'notify-info',
    }

    const lang = inject('$lang')

    function show(text, className = '') {
        if(lang) {
            text = lang(text)
        }
        
        Toastify({
            text: text,
            className: className === '' ? classes.info : className,
            position: 'right',
            stopOnFocus: false,
            close: false,
            onClick: function () { 
                document.querySelector('.toastify').remove();
            }
        }).showToast();
    }

    function error(text) {
        show(text, classes.error);
    }

    function success(text) {
        show(text, classes.success);
    }

    return { show, error, success }
}
