import { serialize } from 'object-to-formdata';
import { ref } from 'vue'
import config from '@/config/api'
import { useRouter } from 'vue-router'
import { useStorage } from '@/composables/storage'
import { useHelpers } from '@/composables/helpers'
import { useNotify } from '@/composables/notify'

export async function useApi(auth, method, model, params = {}, keys = []) {
    const result = ref(null)
    const data = ref(null)
    const status = ref(null)
    const ok = ref(null)
    const errors = ref(null)
    const message = ref(null)

    const notify = useNotify()
    const router = useRouter()

    const { isJson } = useHelpers()

    const token = getToken(auth)

    checkAuth()

    const response = await fetch(
        getUrl(), 
        getOptions()
    )

    status.value = response.status
    ok.value = response.ok
    const responseText = await response.text()
    result.value = isJson(responseText) ? JSON.parse(responseText) : responseText

    if(result.value.data) {
        data.value = result.value.data
    }

    if(result.value.errors) {
        errors.value = result.value.errors
    }

    if(result.value.message) {
        message.value = result.value.message
    }

    if(response.status == 401 || response.status == 403) {
        loginRedirect()
    }

    if(response.status == 429) {
        notify.error('form.error_429')
    }

    function getToken() {
        let token = null

        if(auth) {
            const { get } = useStorage()
            token = get('token')
        }    

        return token
    }

    function checkAuth() {
        if(auth && token == null) {
            loginRedirect()
        }
    }

    function loginRedirect() {
        const url = new URL(window.location)
        if(url.pathname !== '/login') {
            window.location.href = '/login';
            // router.push({ name: 'login' })
        }
    }

    function getOptions() {
        let options = {} 

        if(method == 'index') {
            options = {
                method: 'GET',
            }
        }
        else if(method == 'show') {
            options = {
                method: 'GET',
            }
        }
        else if(method == 'store') {
            options = {
                method: 'POST',
                body: getBody(),
            }
        }
        else if(method == 'update') {
            options = {
                method: 'POST',
                body: getBody({'_method': 'PUT'}),
            }
        }
        else if(method == 'destroy') {
            options = {
                method: 'POST',
                body: getBody({'_method': 'DELETE'}),
            }
        }
        else if(method == 'restore') {
            options = {
                method: 'POST',
                body: getBody({'_method': 'PATCH'}),
            }
        }
        else {
            options = {
                method: 'POST',
                body: getBody(),
            }
        }

        return Object.assign(options, {
            headers: getHeaders(),
        })
    }

    function getHeaders(items = null, format = null) {
        const headers = new Headers({ 'Accept': 'application/json' })

        if(format === 'json') {
            headers.append('Content-Type', 'application/json;charset=utf-8')
        }
        
        if(token !== null) {
            headers.append('Authorization', 'Bearer ' + token)
        }

        if(items) {
            for (const key in items) {
                headers.append(key, items[key])
            }
        }

        return headers
    }

    function getUrl() {
        let pathname = ''

        if(['show', 'update', 'destroy', 'restore'].indexOf(method) > -1) {
            pathname = '/' + keys[0]
        }
        
        const url = new URL(config.baseURL + model + pathname)

        if(params.value !== undefined) {
            params = params.value
        }

        if(method == 'index') {
            for (const key in params) {
                if(params[key]) {
                    let parameter = params[key].value !== undefined ? params[key].value : params[key]

                    if(parameter && parameter !== '') {
                        if(parameter.value !== undefined) {
                            parameter = parameter.value
                        }
        
                        url.searchParams.append(key, parameter)
                    }
                }
            }
        }

        return url
    }

    function getBody(additionalParams = {}, format = null) {
        let result = null
        const resultParams = Object.assign(params, additionalParams)

        if(format == 'json') {
            result = JSON.stringify(resultParams)            
        }
        else {
            result = serialize(resultParams, { 
                indices: true, 
            })
        }

        return result
    }

    return { result, data, status, ok, errors, message }
}
