import { createApp } from 'vue'
import App from './App.vue'
import FeatherIcons from 'vue-feather'
import config from './plugins/config'
import settings from './plugins/settings'
import user from './plugins/user'
import router from './plugins/router'
import lang from './plugins/lang'
import bootstrap from './plugins/bootstrap'
import helpers from './plugins/helpers'
import vSelect from "vue-select";
import { createPinia } from 'pinia'

import '@/sass/main.scss'

const app = createApp(App)

app.component(FeatherIcons.name, FeatherIcons);
app.component('v-select', vSelect);

const pinia = createPinia()

app.use(config)
app.use(settings)
app.use(user)
app.use(pinia)
app.use(router)
app.use(bootstrap)
app.use(helpers)
app.use(lang, ['app', 'menu', 'form', 'order', 'statistics'])

app.mount('#app')
