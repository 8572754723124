import config from '@/config/config'
import api from '@/config/api'
import lang from '@/config/lang'
import echo from '@/config/echo'
import list from '@/config/list'
import roles from '@/config/roles'
import order from '@/config/order'
import pagination from '@/config/pagination'
import routes from '@/config/routes'
import tree from '@/config/tree'

export default {
    install: (app) => {     
        const resultConfig = {}
        
        Object.assign(resultConfig, config, {
            api,
            lang,
            echo,
            list,
            roles,
            order,
            pagination,
            routes,
            tree,
        })

        app.provide('$config', resultConfig)

        app.config.globalProperties.$config = resultConfig
    }
}
