<script setup>
    import { ref } from 'vue'

    const show = ref(false)

    document.addEventListener('scroll', function (e) {
        if (window.innerWidth > 950) {
            if (window.scrollY > 400) {
                show.value = true
            } else {
                show.value = false
            }
        }
    })

    function top() {
        window.scrollTo(0, 0)
    }
</script>

<template>
    <button class="btn btn-primary btn-icon scroll-top" type="button" v-show="show" @click="top()">
        <vue-feather type="arrow-up" size="15"></vue-feather>
    </button>
</template>

<style>
    .btn.scroll-top {
        display: block;
        z-index: 1000;
    }
</style>
