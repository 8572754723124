import { useApi } from '@/composables/api'
import { useStorage } from '@/composables/storage'
import configLang from '@/config/lang'

export function useSettings() {
    const storage = useStorage()

    function getFromStorage(key = null) {
        if(key) {
            return storage.getJson('settings')[key]
        }
        else {
            return storage.getJson('settings')
        }
    }
    
    function set(data, key = null) {
        if(key) {
            const storageData = getFromStorage()
            
            storageData[key] = data
            data = storageData
        }
        
        storage.setJson('settings', data)
    }

    async function getFromApi() {
        const { ok, data } = await useApi(true, 'index', 'settings')

        if(ok.value && data.value) {
            return getApiItems(data.value)
        }

        return null
    }

    function getApiItems(items) {
        let result = {}
    
        for (let i = 0; i < items.length; i++) {
            const key = items[i].key ?? i
            result[key] = getApiOptions(items[i]) 
        }
    
        return result
    }
    
    function getApiOptions(item) {
        if(item.options) {
            let result = {}
    
            if(item.options.length === undefined) {
                return item.options
            }
    
            for (let j = 0; j < item.options.length; j++) {
                const key = item.options[j].key ?? j
    
                result[key] = item.options[j]
    
                if(item.options[j]['lang']) {
                    result[key]['title'] = item.options[j]['lang'][configLang.default]
                }
            }
    
            return result
        }
        else {
            return item
        }
    }

    return { set, getFromStorage, getFromApi, getApiItems, getApiOptions }
}
