import config from '@/config/api'

export default {
    isJson: (str) => {
        try { 
            JSON.parse(str) 
        } catch (e) { 
            return false 
        }
        return true
    },

    rand: (precision = 10000000) => {
        return Math.floor(Math.random() * precision)
    },

    date: (value, locale = null, options = null) => {
        if(!locale) {
            locale = 'ru-RU'
        }

        if(!options) {
            options = { 
                year: 'numeric', 
                month: 'numeric', 
                day: 'numeric', 
                hour: '2-digit', 
                minute: '2-digit' 
            }
        }
        
        const dateObj = new Date(value)

        return dateObj.toLocaleDateString(locale, options)
    },

    img: (path) => {
        if(typeof path !== 'string') {
            return path
        }

        return path.replace(config.imgUrl, '')
    },

    priceRound: (value) => {
        return Number.parseFloat(value).toFixed(2)
    }
}
