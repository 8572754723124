import '@popperjs/core';
// import * as bootstrap from 'bootstrap'
import { Tooltip } from 'bootstrap'

export default {
    install: () => {
        new Tooltip(document.body, {
            selector: "[data-bs-toggle='tooltip']",
        })
    }
}  
