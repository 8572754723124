export default {
    main: {
        home: {
            icon: 'home',
        },
        orders: {
            icon: 'shopping-cart',
        },
        customers: {
            icon: 'user',
        },
        products: {
            icon: 'shopping-bag'
        },
        stores: {
            icon: 'flag'
        },
        stocks: {
            icon: 'table'
        },
        groups: {
            icon: 'check-square'
        },
        categories: {
            icon: 'check-square'
        },
        promotions: {
            icon: 'star'
        },
        contents: {
            icon: 'book-open',
        },
        messages: {
            icon: 'message-circle'
        },
        settings: {
            icon: 'settings',
        },
        users: {
            icon: 'user-check',
        },
        notifications: {
            icon: 'message-square',
        },
        delivery: {
            icon: 'truck',
        },
        import: {
            icon: 'download',
        },
        rewards: {
            icon: 'gift',
        },
        jettons: {
            icon: 'award',
        }
    },

    account: {
        profile: {
            icon: 'user',
        },
        logout: {
            icon: 'power',
            action: 'logout'
        },
    },
}
