const env = import.meta.env

export default {
    params: {
        broadcaster: env.VITE_ECHO_BROADCASTER,
        key: env.VITE_ECHO_KEY,
        wsHost: env.VITE_ECHO_WS_HOST,
        wsPort: env.VITE_ECHO_WS_PORT,
        wssPort: env.VITE_ECHO_WSS_PORT,
        forceTLS: env.VITE_ECHO_FORCE_TLS == '0' ? false : true,
        encrypted: env.VITE_ECHO_ENCRYPTED == '0' ? false : true,
        cluster: env.VITE_ECHO_CLUSTER ?? 'cluster',
        enabledTransports: ['ws', 'wss'],
        transports: ['websocket', 'polling', 'flashsocket'],
        enableLogging: true,
        disableStats: true,
    }
}
